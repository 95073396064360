<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Etapas</h1>
    </div>
    <br />

    <div class="row mb-4">
      <div class="col-sm-2"><button @click="obtener_etapas" class="btn warning-btn">Recargar</button></div>
      <div class="col-sm-2"><button @click="guardar" class="btn secondary-btn">Guardar</button></div>
      <div class="col-sm-2"><button @click="etapa_nueva=true" class="btn principal-btn">Agregar</button></div>
      <div class="col-sm-2"><button @click="eliminar" class="btn danger-btn">Eliminar</button></div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table class="table-etapas">
          <thead>
            <tr>
              <th>Subir/Bajar</th>
              <th>Etapa</th>
              <th>Aplicacion</th>
              <th>Identificador</th>
              <th>Orden</th>
              <th>Tiempo MAX</th>
              <th>Inicio</th>
              <th>Etapa final</th>
              <th>Creación</th>
              <th>Editar / Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(etapa,index) in etapas" :key="etapa.id">
              <td>
                <button v-if="etapa.orden != 1" @click="subir_etapa(index)" class="btn btn-success">Subir</button>
                <button v-if="etapa.orden < etapas.length" @click="bajar_etapa(index)" class="btn btn-danger">Bajar</button>
              </td>
              <td>{{ etapa.etapa }}</td>
              <td>{{ etapa.app }}</td>
              <td>{{ etapa.identificador }}</td>
              <td>{{ etapa.orden }}</td>
              <td>{{ mutator_tiempo(etapa.tiempo_max) }}</td>
              <td>{{ etapa.inicio == 1 ? 'Sí' : 'No' }}</td>
              <td>{{ etapa.etapa_final == 1 ? 'Sí' : 'No' }}</td>
              <td>{{ $moment(etapa.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
              <td>
                <img class="quitar_etapa mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar etapa" @click="editar_etapa(etapa.id)" title="Editar etapa">
                <img class="quitar_etapa" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar etapa" @click="eliminar(index)" title="Elimitar etapa">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Agregar v-if="etapa_nueva" :etapas="etapas" :etapatmp="etapa" @cerrar="cerrar_etapa_nueva" />
  </div>
</template>

<script type="text/javascript">
  import api from '@/apps/metricas/api/etapas'

  import apiCatalogos from '@/apps/metricas/api/catalogos'
  import BPBSwitch from '@/components/Form/Switch'
  import Agregar from './Etapa/Agregar'

  export default {
    components: {
      BPBSwitch, Agregar
    }
    ,data: function() {
      return {
        etapas: []
        ,etapa_nueva: false
        ,etapa: null
      }
    }
    ,mounted: function() {
      this.obtener_etapas();
      // this.$log.info('options al cargar: ',this.dataGridConfig.options)
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.dataGridConfig.options = data;
          this.obtener_etapas();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_etapas: async function() {
        try {
          this.$log.info('options: ')
          this.etapas = (await api.obtener_etapas()).data
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,subir_etapa: function(index) {
        let etapas = [];

        let seleccionado = this.etapas[index]
        let anterior = index-1;
        let previo = index-2;

        for(let i=0; i<this.etapas.length; i++) {
          etapas.push(this.etapas[i]);

          if (i==anterior) {
            etapas[i].orden_anterior = previo;
            etapas[i].orden = seleccionado.orden;
          }

          if (i == index) {
            etapas[i].orden_anterior = etapas[i].orden;
            etapas[i].orden = etapas[i].orden-1;
          }
        }

        etapas = etapas.sort((a,b) => {
          return a.orden - b.orden;
        })

        this.etapas = etapas;
      }
      ,bajar_etapa: function(index) {
        let etapas = [];

        let seleccionado = this.etapas[index]
        let siguiente = index+1;
        let continuo = index+2;

        for(let i=0; i<this.etapas.length; i++) {
          etapas.push(this.etapas[i]);

          if (i==siguiente) {
            etapas[i].orden_anterior = continuo;
            etapas[i].orden = seleccionado.orden-1;
          }

          if (i==index) {
            etapas[i].orden_anterior = etapas[i].orden;
            etapas[i].orden = etapas[i].orden+1;
          }
        }

        etapas = etapas.sort((a,b) => {
          return a.orden - b.orden;
        })

        this.etapas = etapas;
      }
      ,guardar: async function() {
        try {
          for(let i=0; i<this.etapas.length; i++) {
            if (!this.etapas[i].depende_de || this.etapas[i].depende_de == 0)
              delete this.etapas[i].depende_de;

            await api.editar_etapa(this.etapas[i].id, this.etapas[i]);
          }

          this.$helper.showMessage('Exito!','Se guardaron los cambios','success','alert');

          this.obtener_etapas();
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,eliminar: async function(index) {
        try {
          let etapas = [];
          let a_eliminar = this.etapas[index].id;
          
          let orden = 1;
          for(let i=0; i<this.etapas.length; i++) {
            if (i != index) {
              this.etapas[i].orden = orden;
              etapas.push(this.etapas[i]);
              orden++;
            }
          }

          this.etapas = etapas;

          await api.eliminar_etapa(a_eliminar);

          this.guardar();
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_etapa_nueva: function() {
        this.etapa_nueva=false;
        this.etapa = null;
        this.obtener_etapas();
      }
      ,editar_etapa: async function(id) {
        try {
          this.etapa = (await api.obtener_etapa(id)).data;

          this.etapa_nueva = true;
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,mutator_tiempo: function(val) {
        let minutos = (val / 60);
        let horas = (minutos / 60);
        let dias = (horas / 24);
        let semanas = (dias / 7);

        // return val;

        // if (semanas >= 1)
        //   return semanas+' semana(s)';
        // else if (dias >= 1)
        if (dias >= 1)
          return dias+' día(s)';
        
        if (horas >= 1)
          return horas+' hora(s)';
        
        if (minutos >= 1)
          return minutos+' minuto(s)';
        else
          return val+' segundo(s)';
      }
      ,mutator_depende_de: function(id) {
        let nombre = null;

        this.etapas.forEach(etapa => {
          if (etapa.id == id)
            nombre = etapa.etapa;
        });

        return nombre;
      }
    }
  }
</script>

<style lang="scss">
  table {
    width: 100%;

    thead {
      background-color: #245671 !important;

      tr {
        th {
          padding: 15px !important;
          text-align: left;
        }

        th:nth-child(7) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td:last-child {
          text-align: center;
          padding: 3px 8px;
        }
      }

      tr:hover {
        background-color: #FBE4AD !important;
      }

      tr:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }
  }

  .icon-cross:before {
    content: "\ea0f";
  }

  .quitar_etapa {
    width: 20px;
    cursor: pointer;
  }
</style>